<template>
<div class="trading_data_map">
  <TwHeader :title="$t('BreadCrumbs.Trading Data Map')" />
  <div class="tw_container">
    <!-- パンくず 検索エリア -->
    <div class="top_area">
      <TwBreadcrumbs v-if="breadcrumbs.length" :pages="breadcrumbs" />
      <el-form class="search_unit" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Organization')" prop="organization">
            <el-input type="text" v-model="searchForm.organization" placeholder="Enter keyword" clearable></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Process Last Update')" prop="sendDate">
            <el-date-picker
              v-model="searchForm.sendDate"
              type="daterange"
              range-separator="to"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
              @change.native="onDateRangeChange"
              start-placeholder="Start date"
              end-placeholder="End date">
            </el-date-picker>
          </el-form-item>
          <div>
            <tw-button type="secondary" size="medium" icon="search" @click="search">Search</tw-button>
            <tw-button type="default" size="medium" @click="clear" style="margin: 23.5px 0 0 16px">Clear</tw-button>
          </div>
        </div>
      </el-form>
    </div>

    <div class="contents" ref="contents" v-dragscroll>
      <!-- 見出しエリア -->
      <div class="head_area" ref="headArea" :style="{ left: headerLeft }">
        <div class="row organization">
          <div class="head">
            <span>{{$t('Label.Organization')}}</span>
            <figure @click="filter">
              <img v-if="isfilter" src="@/assets/images/icons/ci_filter_on.svg">
              <img v-else class="off" src="@/assets/images/icons/ci_filter_off.svg">
            </figure>
          </div>
        </div>
        <div class="row">
          <div class="head">{{$t('Label.Section')}}</div>
        </div>
        <div class="row">
          <div class="head">{{$t('Label.Trading Pattern')}}</div>
        </div>
        <div class="row">
          <div class="head">{{$t('Label.Trading')}}</div>
        </div>
        <div class="row">
          <div class="head">{{$t('Label.Flow')}}</div>
        </div>
        <div class="row">
          <div class="head">{{$t('Label.Category')}}</div>
        </div>
        <div class="row">
          <div class="head">{{$t('Label.Process')}}</div>
        </div>
      </div>
      <!-- カードエリア -->
      <div class="card_area">
        <!-- Organization -->
        <div class="row organization">
          <template v-for="(item, index) in results[DATA_MAP_LAYER.ORGANIZATION]">
            <TwTradingDataMapItem
              :item="item"
              :index="index"
              :key="`${DATA_MAP_LAYER.ORGANIZATION}_${item.id}`"
              :ref="`${DATA_MAP_LAYER.ORGANIZATION}_${item.id}`"
              :active-id="activeId[DATA_MAP_LAYER.ORGANIZATION]"
              :next-id="activeId[DATA_MAP_LAYER.SECTION] || ''"
              :next-items="results[DATA_MAP_LAYER.SECTION][activeId[DATA_MAP_LAYER.ORGANIZATION]] || {}"
              @click="click(DATA_MAP_LAYER.ORGANIZATION, item, index, getColor(index))"
              type="organization"
              :color="getColor(index)"
            />
          </template>
          <!-- ローディング -->
          <div v-if="isLoading[DATA_MAP_LAYER.ORGANIZATION]" class="loading">
            <el-skeleton :rows="50" animated />
          </div>
        </div>
        <!-- Organization以下 -->
        <div class="row" v-for="layer in items" :key="layer.code" :style="{paddingTop: `${getParentHeight(layer.code)}px`}">
          <template v-for="(item, index) in results[layer.code][activeId[layer.code - 1]]">
            <TwTradingDataMapItem
              :item="item"
              :index="index"
              :key="`${layer.code}_${item.id}_${index}`"
              :ref="`${layer.code}_${item.id}`"
              :active-id="activeId[layer.code]"
              :next-id="activeId[layer.code + 1] || ''"
              :next-items="layer.code !== DATA_MAP_LAYER.PROCESS ? results[layer.code + 1][activeId[layer.code]] || [] : []"
              @click="click(layer.code, item, index)"
              :type="layer.name"
              :active-color="activeColor"
              :parentHeight="getParentHeight(layer.code)"
              :tradingFlow="getTradingFlow(layer.code, layer.name)"
            />
          </template>
          <!-- ローディング -->
          <div v-if="isLoading[layer.code]" class="loading">
            <el-skeleton :rows="50" animated />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// utils
import _ from 'lodash';
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import { $api } from '@/store/ApiClient'
import { getStartDate, getEndDate, initialFlag, onDateRangeChange, clearSearchForm } from '@/utils/searchUtil.js'
// constants
import { DATA_MAP_LAYER, ORGANIZATION_REFINE_TYPE, INITIAL_VIEW_FLG } from 'lib-tw-common'
// components
import TwHeader from '@/components/organisms/TwHeader'
import TwBreadcrumbs from '@/components/molecules/TwBreadcrumbs'
import TwButton from '@/components/atoms/TwButton'
import TwTradingDataMapItem from '@/components/molecules/TwTradingDataMapItem'
// dragScroll
import { dragscroll } from 'vue-dragscroll'

export default {
  name: 'TradingDataMap',
  directives: {
    dragscroll
  },
  components: {
    TwHeader,
    TwBreadcrumbs,
    TwButton,
    TwTradingDataMapItem
  },
  data() {
    return {
      DATA_MAP_LAYER: DATA_MAP_LAYER,
      searchForm: {
        organization: '',
        sendDate: '',
      },
      defaultDate: dayjs().subtract(1, 'month').toDate(),
      // リクエストデータ
      dataMapLayer: 0, // 選択した階層
      id: '', // 上位レイヤーで指定されたオブジェクトID
      initialFlag: INITIAL_VIEW_FLG.OFF, // ON, // 初期表示フラグ
      isfilter: false, // 組織絞り込み区分チェック
      // v-for配列 Organizationを除く nameは子コンポーネント用
      items: [
        { name: 'section', code: DATA_MAP_LAYER.SECTION },
        { name: 'tradingPattern', code: DATA_MAP_LAYER.TRADING_PATTERN },
        { name: 'trading', code: DATA_MAP_LAYER.TRADING },
        { name: 'flow', code: DATA_MAP_LAYER.FLOW },
        { name: 'entity', code: DATA_MAP_LAYER.ENTITY },
        { name: 'process', code: DATA_MAP_LAYER.PROCESS },
      ],
      // レスポンスデータ格納
      results: {
        1: [], // Organization（検索以外では常に固定）
        2: {}, // Section
        3: {}, // Trading Pattern
        4: {}, // Trading
        5: {}, // Flow
        6: {}, // Entity
        7: {}, // Process
      },
      // 表示するID
      activeId: {
        1: '', // Organization
        2: '', // Section
        3: '', // Trading Pattern
        4: '', // Trading
        5: '', // Flow
        6: '', // Entity
        7: '', // Process
      },
      // 読み込み中フラグ
      isLoading: {
        1: false, // Organization
        2: false, // Section
        3: false, // Trading Pattern
        4: false, // Trading
        5: false, // Flow
        6: false, // Entity
        7: false, // Process
      },
      // 色指定
      activeColor: '',
      // カラー6色をループして表示
      colors: [
        'purple',
        'red',
        'yellow',
        'blue',
        'green',
        'orange',
      ],
      headerLeft: 'auto', // 固定ヘッダー横位置
      resizeObserver: null, // サイドメニューリサイズ監視
    }
  },
  computed: {
    ...mapState(['asideShow']),
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Trading Data Map')},
      ];
    },
  },
  created() {
    const queries = this.$store.getters.getQueries('DATAMAP')
    if (queries) {
      this.searchForm = queries
      this.search()
    } else {
      this.fetch()
    }
  },
  mounted() {
    // ヘッダーのスクロール制御
    this.$nextTick(() => {
      this.$refs.contents.addEventListener('scroll', this.headerScroll)
      document.addEventListener('scroll', this.headerScroll)
      this.resizeObserver = new ResizeObserver(entries => {
        this.headerScroll(entries[0].contentRect.width)
      })
      this.resizeObserver.observe(document.querySelector('aside.el-aside'))
    })
  },
  beforeDestroy() {
    this.$refs.contents.removeEventListener('scroll', this.headerScroll)
    document.removeEventListener('scroll', this.headerScroll)
    this.resizeObserver.disconnect()
  },
  methods: {
    // 取引情報を取得します
    async fetch() {
      // 既にデータ取得済みならreturn
      if (this.dataMapLayer && this.results[this.dataMapLayer + 1][this.id]) return
      this.isLoading[this.dataMapLayer + 1] = true // ローディング

      // リクエストパラメータ生成
      let params = {}
      if (!this.dataMapLayer || [1, 2].includes(this.dataMapLayer)) {
        // bff_datamap_1 データマップ情報取得BFF
        params = {
          lslConfig: {
            serviceCode: 'tw-user-entity-bff-api',
            apiCode:'get_/v1/{companyId}/datamap',
            query: {
              dataMapLayer: this.dataMapLayer ? this.dataMapLayer : '',
              id: this.id,
              organizationName: this.searchForm.organization || null, // 絞り込み組織名
              organizationRefineType: this.isfilter ? ORGANIZATION_REFINE_TYPE.ON : ORGANIZATION_REFINE_TYPE.OFF, // 組織を絞り込むかどうか
              initialFlag: this.initialFlag,
            },
            path: {
              companyId: this.$store.state.companyId,
            },
          }
        }
      } else {
        // bff_dm_1 データマップ取引情報取得BFF
        params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'get_/v1/datamap/tradings',
            query: {
              dataMapLayer: this.dataMapLayer,
              id: this.id,
              processLastUpdateFrom: getStartDate(this.searchForm.sendDate) || null,
              processLastUpdateTo: getEndDate(this.searchForm.sendDate) || null,
            },
          }
        }
      }

      // BFFリクエスト
      await $api.request(params)
      .then(res => {
        // レスポンスを各オブジェクトに格納
        if (!this.dataMapLayer) this.results[DATA_MAP_LAYER.ORGANIZATION] = res.objList
        else this.results[this.dataMapLayer + 1][this.id] = res.objList
      })
      .catch(err => {
        this.items = []
        this.$store.dispatch('SHOW_ALERT', err.message)
      })
      this.isLoading[this.dataMapLayer + 1] = false // ローディング
    },
    // 検索を行います
    // NOTE: 検索時に表示されるのは組織名のみで、ドリルダウンした場合に表示される取引フローがFrom-Toによって絞り込まれて表示
    async search() {
      this.dataMapLayer = ''
      this.id = ''
      this.initialFlag = initialFlag(this.searchForm)
      this.$store.commit('SET_QUERIES', {key: 'DATAMAP', queries: _.cloneDeep(this.searchForm)})
      for (let i = 1; i < 8; i++) {
        this.results[i] = []
        this.activeId[i] = ''
      }
      this.isLoading[1] = true
      await this.fetch()
      this.isLoading[1] = false
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', {key: 'DATAMAP', queries: _.cloneDeep(this.searchForm)})
    },
    // クリックで選択したオブジェクト階層より一つ下の情報を取得します
    async click(layer, item, index, color) {
      const id = item.id
      // ローディング中の場合はreturn
      if (_.findIndex(Object.values(this.isLoading)) !== -1) return
      // クリック対象がプロセスの場合はfetchしない
      if (layer === DATA_MAP_LAYER.PROCESS) {
        this.activeId[layer] = `${index}_${id}`
        return
      }
      this.dataMapLayer = layer
      this.id = id
      this.initialFlag = INITIAL_VIEW_FLG.OFF
      for (let i = 0; i <  8 - layer; i++) {
        this.activeId[layer + i] = ''
      }
      this.activeId[this.dataMapLayer] = id
      if (color) this.activeColor = color
      await this.fetch()
    },
    // フィルターボタン押下時の処理
    filter() {
      this.isfilter = !this.isfilter
      this.search()
    },
    // 連番カラー名の取得
    getColor(index) {
      const order = index < 6 ? index : index % 6
      return this.colors[order]
    },
    // 親のアクティブな要素の高さを取得
    getParentHeight(code) {
      const ref = this.$refs[`${code - 1}_${this.activeId[code - 1]}`]
      if (ref) {
        return ref[0].$el.offsetTop + (ref[0].$el.clientHeight / 2) + 16
      } else return 0
    },
    // ヘッダーの横スクロール制御
    headerScroll(width) {
      this.$refs.headArea.style.left =
        -this.$refs.contents.scrollLeft
        -document.querySelector('html').scrollLeft
        +(isNaN(width) ? document.querySelector('aside.el-aside').clientWidth : width)
        +'px'
    },
    // 取引詳細画面へ遷移するための情報取得
    getTradingFlow(code, name) {
      if (name !== 'flow') return {}
      const target = this.results[code - 1]
      const tradingId = this.activeId[code - 1]
      const tradingPatternId = this.activeId[code - 2]
      if (target && tradingId && tradingPatternId) {
        const flow = _.find(target[tradingPatternId], { id: tradingId })
        return flow ? flow : {}
      }
      else return {}
    },
    onDateRangeChange,
  },
}
</script>

<style lang="scss">
.trading_data_map {
  .tw_container {
    padding: 0;
  }
  .top_area {
    padding: 11px 58px 0;
    display: flex;
    justify-content: space-between;
    .search_unit {
      margin-top: 16px;
    }
  }
  .contents {
    cursor: grab;
    width: 100%;
    overflow-x: scroll;
    height: calc(100% - 118px);
    background: $color_gray_100;
    padding: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    .row {
      height: auto;
      min-width: 260px;
      max-width: 260px;
      padding: 16px;
      position: relative;
      &.organization {
        min-width: 275px;
        max-width: 275px;
      }
      border-right: 1px solid $color_gray_300;
      .loading {
        width: 260px;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        padding: 16px;
      }
    }
    .head_area {
      display: flex;
      z-index: 2;
      background: $color_gray_100;
      position: fixed;
      padding-left: 16px;
    }
    .head {
      font-size: 16px;
      font-weight: bold;
      color: $color_gray_800;
      display: flex;
      align-items: center;
      justify-content: space-between;
      figure {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      img {
        cursor: pointer;
        width: 26px;
        height: 26px;
        &.off {
          border-radius: 50%;
          border: 0.764706px solid rgba(94, 88, 115, 0.4);
        }
      }
    }
    .card_area {
      display: flex;
      // overflow-y: scroll;
      flex: auto;
      margin-top: 58px;
      padding-left: 16px;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(191, 191, 205, 0.5);
        border-radius: 10px;
      }
      .row {
        // display: flex;
        // flex-direction: column;
        padding-bottom: 24px;
      }
    }
  }
}
// Hack Mac Safari
_::-webkit-full-page-media, _:future, :root .trading_data_map .contents {
  display: block;
}
_::-webkit-full-page-media, _:future, :root .trading_data_map .contents .card_area {
  min-height: calc(100% - 58px);
}
</style>
